/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*cover by Esther via Pexels*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FirstAttempt, HardCodedTabsAboveCode, TabsAboveCode, TabsInTitle} from "./";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    code: "code",
    pre: "pre",
    br: "br",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components), {Aside, YouTube} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!YouTube) _missingMdxReference("YouTube", true);
  return React.createElement(React.Fragment, null, "\n", "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "I made a multilingual codeblock component to show the same piece of code\nwritten in different programming languages."), React.createElement(_components.p, null, "You can see it in action with ", React.createElement(_components.a, {
    href: "/garden/sieve-of-eratosthenes#final-code"
  }, "an example of The Sieve of\nEratosthenes"), ".")), "\n", React.createElement(_components.h2, {
    id: "proof-of-concept"
  }, "Proof of concept"), "\n", React.createElement(_components.p, null, "I started off simple, and I use that word because I used a package that provides a tab component in React."), "\n", React.createElement(_components.p, null, "Making one myself was a lot more complex than anticipated I had anticipated, so thank you ", React.createElement(_components.a, {
    href: "https://reach.tech/tabs/"
  }, React.createElement(_components.code, null, "@reach/tabs")), "."), "\n", React.createElement(_components.p, null, "My first attempt were 3 tabs with hardcoded labels, very fragile, but perfect as proof of concept."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    title: "MultiLangCodeBlock.js"
  }, "import { Tabs, TabList, Tab, TabPanels, TabPanel } from \"@reach/tabs\";\nimport \"@reach/tabs/styles.css\";\n\nconst MultiLangCodeBlock = (props) => {\n  return (\n    <Tabs>\n      <TabList>\n        <Tab>1</Tab>\n        <Tab>2</Tab>\n        <Tab>3</Tab>\n      </TabList>\n      <TabPanels>\n        <TabPanel>{props.children[0]}</TabPanel>\n        <TabPanel>{props.children[1]}</TabPanel>\n        <TabPanel>{props.children[2]}</TabPanel>\n      </TabPanels>\n    </Tabs>\n  );\n};\n")), "\n", React.createElement(_components.p, null, "I can import that component into a ", React.createElement(_components.code, null, ".mdx"), " blogpost and pass it an array of 3 items to be rendered."), "\n", React.createElement(_components.p, null, "I can even pass a React component as an array item, it’s great."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "blogpost.mdx",
    hl: "4-6"
  }, "import { MultiLangCodeBlock } from \"./src/components/MultiLangCodeBlock\";\nimport { Hobbits } from \"./src/components/Hobbits\";\n\n<MultiLangCodeBlock>\n  {[\"They're taking the\", \"Hobbits to Isengard!\", <Hobbits />]}\n</MultiLangCodeBlock>\n")), "\n", React.createElement(_components.p, null, "The result can be seen below.\nBe sure to check out all tabs."), "\n", React.createElement(FirstAttempt, null, ["They're taking the", "Hobbits to Isengard!", React.createElement(YouTube, {
    key: "hobbit-vid",
    youTubeId: "uE-1RPDqJAY",
    skipTo: {
      h: 0,
      m: 0,
      s: 18
    }
  })]), "\n", React.createElement(_components.h2, {
    id: "codeblocks"
  }, "Codeblocks"), "\n", React.createElement(_components.p, null, "Let’s try to pass a codeblock as an item in that array."), "\n", React.createElement(HardCodedTabsAboveCode, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const language = \"JavaScript\";\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python"
  }, "language = \"Python\"\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let language = \"Rust\";\n"))), "\n", React.createElement(_components.p, null, "That worked!"), "\n", React.createElement(_components.p, null, "Be sure to pass those codeblocks as markdown, not as a literal JavaScript array."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "blogpost.mdx"
  }, "<MultiLangCodeBlock>\n\n```js\nconst language = \"JavaScript\";\n```\n\n```python\nlanguage = \"Python\"\n```\n\n```rust\nlet language = \"Rust\";\n```\n\n</MultiLangCodeBlock>\n")), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "The empty lines before and after each block are significant.", React.createElement(_components.br), "\n", "It’s something you need to do in MDXv1 for it to treat the text inside the component as markdown.")), "\n", React.createElement(_components.h3, {
    id: "tab-labels"
  }, "Tab labels"), "\n", React.createElement(_components.p, null, "I replaced the hardcoded amount of tabs, and made it so the tab labels were the language from the triple backtick codeblock."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Remember, the codeblocks are treated as markdown."), React.createElement(_components.p, null, "I told MDX to use ", React.createElement(_components.a, {
    href: "/blog/theme-ui-syntax-highlighting"
  }, "my own ", React.createElement(_components.code, null, "CodeBlock"), " component"), " every time it sees a triple backtick block in a ", React.createElement(_components.code, null, ".mdx"), " file.")), "\n", React.createElement(_components.p, null, "Using my single language ", React.createElement(_components.a, {
    href: "/blog/theme-ui-syntax-highlighting"
  }, React.createElement(_components.code, null, "CodeBlock"), " component"), " means the fancy options (like highlighting specific lines) work too!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    title: "MultiLangCodeBlock.js",
    hl: "5-10,14-16"
  }, "const MultiLangCodeBlock = (props) => {\n  return (\n    <Tabs>\n      <TabList>\n        {props.children.map((child) => {\n          const [language] = child.props.children.props.className\n            .replace(/language-/, ``)\n            .split(` `);\n          return <Tab>{language}</Tab>;\n        })}\n      </TabList>\n\n      <TabPanels>\n        {props.children.map((child) => {\n          return <TabPanel>{child}</TabPanel>;\n        })}\n      </TabPanels>\n    </Tabs>\n  );\n};\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "That ", React.createElement(_components.code, null, "child.props.children.props.className"), " line is a bit hairy."), React.createElement(_components.p, null, "It’s pulling the ", React.createElement(_components.code, null, "className"), " from that single language codeblock, then turning it into the\nlanguage for that codeblock.")), "\n", React.createElement(_components.p, null, "This ", React.createElement(_components.code, null, "mdx"), " will generate the output underneath this block, neat!"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "blogpost.mdx"
  }, "<MultiLangCodeBlock>\n\n```js title=index.js numberLines hl=1\nconst language = \"JavaScript\";\n\nconsole.log(language);\n```\n\n```python title=index.py numberLines hl=1\nlanguage = \"Python\"\n\nprint(language)\n```\n\n```rust title=index.rs numberLines hl=1\nlet language = \"Rust\";\n\nprintln!(\"{}\", language);\n```\n\n</MultiLangCodeBlock>\n")), "\n", React.createElement(TabsAboveCode, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js",
    numberLines: true,
    hl: "1"
  }, "const language = \"JavaScript\";\n\nconsole.log(language);\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "index.py",
    numberLines: true,
    hl: "1"
  }, "language = \"Python\"\n\nprint(language)\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "index.rs",
    numberLines: true,
    hl: "1"
  }, "let language = \"Rust\";\n\nprintln!(\"{}\", language);\n"))), "\n", React.createElement(_components.p, null, "This doesn’t look too bad."), "\n", React.createElement(_components.h3, {
    id: "buttons-inside-the-codeblock"
  }, "Buttons inside the codeblock"), "\n", React.createElement(_components.p, null, "I wanted the tab buttons to be inside the header of a codeblock, to the right of the title."), "\n", React.createElement(_components.p, null, "The tab buttons being ", React.createElement(_components.em, null, "inside"), " the codeblock was a problem.\nThat meant the button for a tab would be inside the content for a tab, that doesn’t sound right."), "\n", React.createElement(_components.p, null, "I decided to manually render my single language ", React.createElement(_components.code, null, "CodeBlock"), " component without a top part.\nThe ", React.createElement(_components.code, null, "CodeBlock"), " component would no longer be responsible for that header, the ", React.createElement(_components.code, null, "MultiLangCodeBlock"), " would take on that responsability."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "title"), " option is what causes the single language component to have a header.\nSo I prevented that option from reaching the single language block by not passing that title as a prop."), "\n", React.createElement(_components.p, null, "I still want the title to be displayed however, so I have to use that title information in the new ", React.createElement(_components.code, null, "MultiLangCodeBlock"), "."), "\n", React.createElement(_components.p, null, "That meant I needed a bit of state management to keep track of the current tab index, the title, and the label for that tab."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "MultiLangCodeBlock"), " looked great after a little CSS to display the tab buttons to the right of the title and make that entire line ", React.createElement(_components.em, null, "look"), " like the top of my single language codeblocks."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "MultiLangCodeBlock.js"
  }, "const MultiLangCodeBlock = ({ children }) => {\n  const codeTitles = children.map(\n    (child) => child?.props?.children?.props?.title\n  );\n  const tabLabels = children.map((child) =>\n    child?.props?.children?.props?.className.replace(/language-/, ``).split(` `)\n  );\n\n  const [tabIndex, setTabindex] = useState(0);\n  const [title, setTitle] = useState(codeTitles[0]);\n\n  const handleTabsChange = (index) => {\n    setTabindex(index);\n    setTitle(codeTitles[index]);\n  };\n\n  return (\n    <Tabs index={tabIndex} onChange={handleTabsChange}>\n      <div sx={{ display: \"flex\", variant: `styles.CodeBlock.title` }}>\n        <div sx={{ flex: 1 }}>{title}</div>\n        <TabList\n          sx={{\n            color: \"mutedText\",\n            \"[data-selected]\": { color: \"mutedPrimary\" },\n          }}\n        >\n          {tabLabels.map((label) => (\n            <Tab key={label}>{label}</Tab>\n          ))}\n        </TabList>\n      </div>\n      <TabPanels>\n        {children.map((child) => {\n          // split off title so the CodeBlock from the theme doesn't render a header, this component does that\n          const { title, ...blockProps } = child.props.children.props;\n          return (\n            <TabPanel key={blockProps.className}>\n              <CodeBlock {...blockProps} />\n            </TabPanel>\n          );\n        })}\n      </TabPanels>\n    </Tabs>\n  );\n};\n")), "\n", React.createElement(Aside, {
    variant: "success"
  }, React.createElement(_components.p, null, "Behold! A Multilingual codeblock!")), "\n", React.createElement(_components.p, null, "What’s in the ", React.createElement(_components.code, null, ".mdx"), " file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-mdx",
    title: "mypost.mdx"
  }, "<TabsInTitle>\n\n```js title=index.js numberLines hl=1\nconst language = \"JavaScript\";\n\nconsole.log(language);\n```\n\n```python title=index.py numberLines hl=1\nlanguage = \"Python\"\n\nprint(language)\n```\n\n```rust title=index.rs numberLines hl=1\nlet language = \"Rust\";\n\nprintln!(\"{}\", language);\n```\n\n</TabsInTitle>\n")), "\n", React.createElement(_components.p, null, "The resulting output:"), "\n", React.createElement(TabsInTitle, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js",
    numberLines: true,
    hl: "1"
  }, "const language = \"JavaScript\";\n\nconsole.log(language);\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python",
    title: "index.py",
    numberLines: true,
    hl: "1"
  }, "language = \"Python\"\n\nprint(language)\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "index.rs",
    numberLines: true,
    hl: "1"
  }, "let language = \"Rust\";\n\nprintln!(\"{}\", language);\n"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
